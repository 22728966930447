
import { Options, Vue } from 'vue-class-component';
import { StorageServices } from '@/services/StorageServices';
import { CustomerClient } from '@/services/services';
import * as OM from '@/model';

@Options({
    components: {
    }
})
export default class Favorites extends Vue {

    customer: OM.Customer = new OM.Customer();

    searchText: string = "";
    searchMode: boolean = false;
    loaded: boolean = false;

    created() {
        CustomerClient.getProfile()
        .then(x => {
            this.customer = x;
            this.loaded = true;
        })
    }

    get filteredFavorites() {
        if(!this.searchText)
			return this.customer.favorites;

		var temp = this.searchText.trim().toLowerCase();

		return this.customer.favorites.filter( x =>  {
			if(x.exhibitionItem.text.trim().toLowerCase().indexOf(temp) != -1)
				return x;

			if(x.exhibition.text.trim().toLowerCase().indexOf(temp) != -1)
				return x;

            if(x.author.text.trim().toLowerCase().indexOf(temp) != -1)
				return x;
		});
    }
    
}
